import {
  Col,
  Row,
  Skeleton,
  Breadcrumb,
  Button,
  message,
  Modal,
  Select,
  Tooltip,
} from "antd";
import {
  EMPTY_VALUE,
  INVALID_TRANSLATION,
  SESSION_DATE_TIME_FORMAT,
  WEB_APP_URL,
} from "../../utils/const";
import EditBtn from "./EditBtn";
import { useIntl } from "react-intl";
import { useQueryHook } from "../../hooks/useQueyHook";
import {
  MatchingNotPaired,
  addSession,
  getVscsBasedOnDepartment,
  updateTutorAvailability,
  fetchStudentsById,
} from "../../helpers/IS_api";
import { useMutation } from "react-query";
import {
  addMessage,
  generateSessionId,
  sessionURL,
  splitURL,
} from "../../helpers/helpers";

import {
  RiCheckboxCircleLine,
  RiCloseCircleLine,
  RiArrowLeftLine,
} from "react-icons/ri";

import { Console, FormatToSessionDate, Translation } from "../../utils";
import { useHistory, useLocation } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";

type TableItemViewHeaderProps = {
  payload: any;
  page: string;
  loading: boolean;
  collection: string;
  collectionItem: string;
  editPath: string;
  allowEdit?: boolean;
};

interface LocationState {
  fromItemColumnOne?: boolean;
}

export default function TableItemViewHeader(props: TableItemViewHeaderProps) {
  const {
    page,
    loading,
    collection,
    collectionItem,
    editPath,
    allowEdit = true,
    payload,
  } = props;
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation<LocationState>();
  const [protocol, host] = splitURL(WEB_APP_URL);
  const [studentsToBeMatched, setStudentsToBeMatched] = useState<any[]>([]);
  const [nextAvailability, setNextAvailability] = useState<number>(0);
  const [fromItemColumnOne, setFromItemColumnOne] = useState(false);
  const sessionPlaceholder = Translation("session") || INVALID_TRANSLATION;
  const operationPlaceholder =
    Translation("something-went-wrong-while-trying-to-add") ||
    INVALID_TRANSLATION;

  const { tutorPreferences, userId, userRole, program, gender } = payload || {};
  const emptySlot = tutorPreferences?.availability
    ?.filter((slot: any) => !slot.taken)
    .sort((a: any, b: any) => a.dayOfTheWeek - b.dayOfTheWeek);
  const nextSlot = emptySlot?.[nextAvailability];
  const { startDate, endDate } = FormatToSessionDate(nextSlot);
  const studentToBeMatchedBasedOnAvailability = useMemo(
    () =>
      studentsToBeMatched?.filter((student: any) =>
        student.availability.some(
          (availability: any) =>
            availability.dayOfTheWeek === nextSlot?.dayOfTheWeek &&
            availability.startTime === nextSlot?.startTime &&
            availability.endTime === nextSlot?.endTime
        )
      ),
    [studentsToBeMatched, nextSlot]
  );

  const [vscList, setVscList] = useState<any[]>([]);
  const [selectedVsc, setSelectedVsc] = useState<any>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const isHomeClassTutor =
    userRole === "tutor" && program?.programId !== "az-fac-5f87232";

  const [studentDetails, setStudentDetails] = useState<any>(null);

  const fetchStudentDetails = async (studentId: string) => {
    try {
      const response = await fetchStudentsById(studentId);
      setStudentDetails(response.data); // Assuming the response data structure
    } catch (error) {
      console.error("Failed to fetch student details", error);
    }
  };

  const tooltipStyles = {
    backgroundColor: "white",
    color: "black",
    padding: "10px",
    borderRadius: "4px",
    maxWidth: "300px",
  };

  const titleStyles = {
    color: "#FF6B43",
    fontWeight: "bold",
  };

  const valueStyles = {
    color: "black",
  };

  useEffect(() => {
    if (isOpen && studentToBeMatchedBasedOnAvailability?.length > 0) {
      const studentId = studentToBeMatchedBasedOnAvailability[0].studentId;
      fetchStudentDetails(studentId);
    }
  }, [isOpen, studentToBeMatchedBasedOnAvailability]);

  useQueryHook({
    queryKey: [
      "not-paired",
      userId,
      {
        gender: gender,
        levelId:
          tutorPreferences?.assignment?.level?.length > 0
            ? tutorPreferences?.assignment?.level?.map(
                (level: any) => level.levelId
              )
            : [],
        sectorId:
          tutorPreferences?.assignment?.sectors?.length > 0
            ? tutorPreferences?.assignment?.sectors.map(
                (sector: any) => sector.sectorId
              )
            : [],
        departmentId: tutorPreferences?.assignment?.department?.departmentId,
        tutorAvailability: emptySlot?.[nextAvailability],
        subjects: tutorPreferences?.subjectToTeach,
        filter: {
          mode: "tutor",
          program: tutorPreferences?.program?.programId,
          status: "unmatched",
        },
        search: "",
        program: tutorPreferences?.program?.programId,
      },
      payload,
    ],
    queryFn: MatchingNotPaired,
    queryOptions: {
      onSuccess: ({ data: { data, status } }: any) => {
        if (status === "failed") {
          setStudentsToBeMatched([]);
          setNextAvailability((prev: any) => prev + 1);
          return;
        } else {
          setStudentsToBeMatched(data);
        }
      },
      onError: (error: any) => {
        Console(error);
      },
      enabled: userRole === "tutor" && studentsToBeMatched?.length === 0,
    },
  });
  useQueryHook({
    queryKey: [
      "/list-vscs-department",
      tutorPreferences?.assignment?.department?.departmentId,
    ],
    queryFn: getVscsBasedOnDepartment,
    queryOptions: {
      onSuccess: ({ data: { data } }: any) => {
        setVscList(data);
      },
      onError: (error: any) => {
        Console(error);
        setVscList([]);
      },
      enabled:
        isOpen && !!tutorPreferences?.assignment?.department?.departmentId,
    },
  });

  const mutation = useMutation(addSession, {
    onSuccess: () => {
      message.success({
        content: addMessage(sessionPlaceholder, intl),
        icon: <RiCheckboxCircleLine className="remix-icon" />,
      });
    },

    onError: (error: any) => {
      Console(error);

      message.error({
        content: `${operationPlaceholder} ${sessionPlaceholder}`,
        icon: <RiCloseCircleLine className="remix-icon" />,
      });
    },
  });

  useEffect(() => {
    if (location.state?.fromItemColumnOne) {
      setFromItemColumnOne(true);
    }
  }, [location.state]);

  const handleCreateRecurringSession = async () => {
    const { tutorPreferences, userId, contactDetails } = payload;
    if (emptySlot.length === 0) {
      message.error("No empty slot available");
      return;
    }

    if (studentToBeMatchedBasedOnAvailability.length === 0) {
      message.error("No students available for the selected slot");
      return;
    }

    const sessionId = generateSessionId();
    const sessionLink = protocol + sessionURL(host, sessionId);
    const sessionData = {
      sessionId: sessionId,
      program: tutorPreferences?.program?.programId,
      sessionType: "private-session",
      status: "session-0-to-be-scheduled",
      tutors: [
        {
          userId: userId,
          fullName: `${contactDetails?.firstName} ${contactDetails?.lastName}`,
          absence: true,
        },
      ],
      students: [
        {
          userId: studentToBeMatchedBasedOnAvailability[0].studentId,
          fullName: studentToBeMatchedBasedOnAvailability[0].studentFullName,
          absence: true,
        },
      ],
      vsc: [
        {
          userId: selectedVsc?.userId,
          fullName: selectedVsc?.fullName,
          absence: true,
        },
      ],
      school: "Autre",
      placeOrRoom: "",
      scheduleDuringHolidays: false,
      sessionLink: sessionLink,
      sessionDate: {
        startDate,
        endDate,
      },
      recurrence: { recurrenceNumber: 0, recurrenceName: "weekly" },
    };
    mutation.mutate({ sessionData, origin: "auto-affectation" });
    await updateTutorAvailability({
      tutorId: userId,
      availabilityId: emptySlot?.[nextAvailability]._id,
      taken: true,
    });
    setTimeout(() => {
      history.push(`/tutoring/sessions`);
    }, 1000);
  };

  const handleBackButtonClick = () => {
    if (userId) {
      localStorage.setItem("activePanelId", userId);

      history.goBack();
    } else {
      console.error("User ID is not available in the payload.");
    }
  };
  return (
    <>
      <Row className="hp-mb-40" align="middle" justify="space-between">
        <Col>
          <Breadcrumb className="hp-large-breadcrumb">
            <Breadcrumb.Item>
              <span className="hp-text-color-black-60">
                {collection || EMPTY_VALUE}
              </span>
            </Breadcrumb.Item>

            <Breadcrumb.Item>
              {loading && <Skeleton.Input active={true} />}
              {!loading && (
                <span className="hp-text-color-primary-1">
                  {collectionItem || EMPTY_VALUE}
                </span>
              )}
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>

      <div
        style={{
          display: "flex",
          gap: "2rem",
          marginBottom: "2rem",
          justifyContent: "space-between",
        }}
      >
        {fromItemColumnOne && (
          <Button
            onClick={handleBackButtonClick}
            icon={<RiArrowLeftLine />}
            style={{
              marginRight: "1rem",
              backgroundColor: "transparent",
              border: "none",
            }}
          />
        )}

        {allowEdit && (
          <Row className="hp-mb-10" align="middle" justify="space-between">
            <Col>
              <EditBtn page={page} path={editPath} />
            </Col>
          </Row>
        )}

        {isHomeClassTutor && (
          <>
            <Button
              type="primary"
              loading={mutation.isLoading}
              disabled={
                tutorPreferences?.onBoardingStep < Number("3") ||
                tutorPreferences?.matchedStudents?.length ===
                  tutorPreferences?.assignment?.numberOfStudentsToSupport ||
                studentsToBeMatched.length === 0 ||
                tutorPreferences?.status !== "active"
              }
              onClick={() => setIsOpen(true)}
            >
              Affectation automatique ({" "}
              {tutorPreferences?.matchedStudents?.length} /{" "}
              {tutorPreferences?.assignment?.numberOfStudentsToSupport} )
            </Button>

            <Modal
              title={
                vscList.length ? (
                  <>
                    Veuillez selectionner le VSC associé à la séance de l'élève{" "}
                    <Tooltip
                      title={
                        <div>
                          <div>
                            Nom et prénom :{" "}
                            {
                              studentToBeMatchedBasedOnAvailability[0]
                                .studentFullName
                            }
                          </div>
                          <div>
                            Email :{" "}
                            {studentDetails?.data?.contactDetails?.email ||
                              "Non disponible"}
                          </div>
                          <div>
                            Téléphone :{" "}
                            {studentDetails?.data?.contactDetails
                              ?.phoneNumber || "Non disponible"}
                          </div>
                          <div>
                            Programme :{" "}
                            {studentDetails?.data?.program?.programName ||
                              "Non disponible"}
                          </div>
                          <div>
                            Niveau :{" "}
                            {studentDetails?.data?.assignment?.level[0]
                              .levelName || "Non disponible"}
                          </div>
                          <div>
                            Matières à enseigner :{" "}
                            {studentDetails?.data?.assignment
                              ?.subjectToStudy === "literary-and-first"
                              ? "Matières littéraires en priorité"
                              : studentDetails?.data?.assignment
                                    ?.subjectToStudy === "scientific-and-first"
                                ? "Matières scientifiques en priorité"
                                : studentDetails?.data?.assignment
                                      ?.subjectToStudy === "both-subjects"
                                  ? "Un peu de tout !"
                                  : "Non disponible"}
                          </div>
                          <div>
                            Département :{" "}
                            {studentDetails?.data?.assignment?.department
                              ? `${studentDetails.data.assignment.department.departmentName} (${studentDetails.data.assignment.department.departmentId})`
                              : "Non disponible"}
                          </div>
                          <div>
                            Etablissement :{" "}
                            {studentDetails?.data?.assignment?.establishments[0]
                              ?.establishmentName || "Non disponible"}
                          </div>
                        </div>
                      }
                    >
                      <span>
                        {
                          studentToBeMatchedBasedOnAvailability[0]
                            .studentFullName
                        }
                      </span>
                    </Tooltip>
                  </>
                ) : (
                  ""
                )
              }
              open={isOpen}
              width={400}
              confirmLoading={mutation.isLoading}
              closable={false}
              onCancel={() => setIsOpen(false)}
              onOk={handleCreateRecurringSession}
              centered
            >
              {!vscList.length ? (
                <h4
                  style={{
                    textAlign: "center",
                    color: "red",
                  }}
                >
                  Pas de VSC disponible pour le moment
                </h4>
              ) : (
                <>
                  <Select
                    placeholder="Selectionner un VSC"
                    style={{ width: "100%" }}
                    onSelect={(value) => setSelectedVsc(JSON.parse(value))}
                  >
                    {vscList.map((vsc) => (
                      <Select.Option
                        value={JSON.stringify({
                          fullName: `${vsc.contactDetails.firstName} ${vsc.contactDetails.lastName || ""}`,
                          userId: vsc.userId,
                        })}
                        key={vsc.contactDetails}
                      >
                        {vsc.contactDetails.firstName}{" "}
                        {vsc.contactDetails.lastName || ""}
                      </Select.Option>
                    ))}
                  </Select>
                </>
              )}
            </Modal>
          </>
        )}
      </div>
    </>
  );
}
