import { useCallback, useEffect, useState, useRef } from "react";
import Cookies from "universal-cookie";
import axios from "axios";
import jwt from "jsonwebtoken";

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
    "api-key": process.env.REACT_APP_SERVER_API_KEY,
  },
});

const useAxiosInterceptor = () => {
  const cookies = new Cookies();

  const [authToken, setAuthToken] = useState(
    cookies.get("secure-access-token") || ""
  );

  // Auth Token Cookies Management

  const setAuthTokenCookies = useCallback((token: string) => {
    cookies.set("secure-access-token", token, {
      path: "/",
      expires: new Date(Date.now() + 1000 * 60 * 60 * 24),
    });
  }, []);

  const removeAuthTokenCookies = useCallback(() => {
    cookies.remove("secure-access-token");
  }, []);

  // Role Token Cookies Management
  const getRoleTokenCookie = useCallback(
    () => cookies.get("secure-role-token"),
    []
  );
  const setRoleTokenCookies = useCallback(
    ({ roleToken, userId }: { roleToken: string; userId?: string }) => {
      if (!roleToken) return;
      try {
        if (!process.env.REACT_APP_ROLE_TOKEN_SECRET) {
          throw new Error(
            "Environment variable REACT_APP_ROLE_TOKEN_SECRET is not set."
          );
        }

        let encodedRoleToken;
        try {
          encodedRoleToken = jwt.sign(
            { role: roleToken, userId: userId },
            process.env.REACT_APP_ROLE_TOKEN_SECRET
            // { expiresIn: "30d" }
          );
        } catch (error: any) {
          throw new Error(error);
        }

        if (encodedRoleToken) {
          cookies.set("secure-role-token", encodedRoleToken, {
            path: "/",
            expires: new Date(Date.now() + 1000 * 60 * 60 * 24),
          });
        }
      } catch (error) {
        console.log("Error setting role token:", error);
        throw new Error("Error setting role token.");
      }
    },
    []
  );

  const removeRoleTokenCookies = useCallback(
    () => cookies.remove("secure-role-token"),
    []
  );

  const getUserIdCookies = useCallback(() => cookies.get("userId"), []);
  const setUserIdCookies = useCallback((userId: string) => {
    cookies.set("userId", userId, {
      path: "/",
      expires: new Date(Date.now() + 1000 * 60 * 60 * 24),
    });
  }, []);

  const removeAllCookies = useCallback(() => {
    cookies.remove("secure-access-token", { path: "/" });
    cookies.remove("secure-role-token", { path: "/" });
    cookies.remove("userId", { path: "/" });
  }, []);

  // Token Refresh Logic

  useEffect(() => {
    // Request interceptor
    const requestInterceptor = axiosInstance.interceptors.request.use(
      async (config) => {
        const access_token = cookies.get("secure-access-token");
        const roleToken = getRoleTokenCookie();
        const userId = getUserIdCookies();

        if (access_token)
          config.headers["Authorization"] = `Bearer ${access_token}`;
        if (roleToken) config.headers["x-role-token"] = roleToken;
        if (userId) config.headers["userId"] = userId;

        return config;
      },
      (error) => Promise.reject(error)
    );

    // Response interceptor
    const responseInterceptor = axiosInstance.interceptors.response.use(
      (response) => response,
      async (error) => {
        const originalRequest = error.config;
        if (
          error.response?.status === 401 &&
          error.response?.statusText === "Unauthorized" &&
          !originalRequest._retry
        ) {
          originalRequest._retry = true;

          removeAllCookies();
          localStorage.removeItem("user");
          window.location.reload();
        }

        return Promise.reject(error);
      }
    );

    // Cleanup interceptors when component unmounts
    return () => {
      axiosInstance.interceptors.request.eject(requestInterceptor);
      axiosInstance.interceptors.response.eject(responseInterceptor);
    };
  }, [
    setAuthTokenCookies,
    removeAuthTokenCookies,
    removeRoleTokenCookies,
    removeAllCookies,
  ]);

  return {
    setAuthToken,
    setAuthTokenCookies,
    removeAuthTokenCookies,
    getRoleTokenCookie,
    setRoleTokenCookies,
    removeRoleTokenCookies,
    removeAllCookies,
    setUserIdCookies,
    getUserIdCookies,
  };
};

export default useAxiosInterceptor;
